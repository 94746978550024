import React from "react"

import Container from "../Layout/Container"
import RxIndicator from "../Elements/RxIndicator"

const PrescriptionRequired = ({ medicines }) => {
  return (
    <Container isCentered customClassName="w-full">
      <div className="bg-gray-light rounded-md p-1">
        <div className="flex gap-2">
          <RxIndicator />
          <div>
            <p>
              We noticed you have added an item that requires a{" "}
              <b>prescription</b>. Please make sure to upload a prescription for
              the following medicines:
            </p>
            <ul className="mt-1 list-disc">
              {medicines.map((medicine) => {
                return <li className="pb-0 ml-1">{medicine?.productTitle}</li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default PrescriptionRequired
